




















































































































































































































































































































































































































































































































































































































































































































































































.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 100%;
      padding-left: 10rem;
    }
    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
  .paraCompleteConditionInput {
    display: inline-block;
    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}
